import { Link, Route, Routes } from "react-router-dom";
import { InfoPanel, InfoPanelWrapper } from "../common/InfoPanel";
import { ContentSection, TableOfContents } from "../common/TableOfContents";

import anri from "../assets/images/anri.png";
import ctap from "../assets/images/ctap.jpg";
import elpub from "../assets/images/elpub.png";
import nmniz from "../assets/images/nmniz.png";
import rko from "../assets/images/rko.png";
import rkz from "../assets/images/rkz.jpg";
import ropniz from "../assets/images/ropniz.png";

function AboutPublisher() {
  return (
    <article>
      <h2>Об издательстве</h2>
      <p>
        Издательство основано в 2002 году как частное предприятие{" "}
        <b>ООО «Силицея-Полиграф»</b>.
      </p>
      <p>
        Издательство специализируется на издании журналов «
        <Link to="/journals/russian-journal-of-cardiology">
          Российский кардиологический журнал
        </Link>
        » и «
        <Link to="/journals/cardiovascular-therapy-and-prevention">
          Кардиоваскулярная терапия и профилактика
        </Link>
        » и книг по кардиологии в партнерстве с Всероссийским научным обществом
        кардиологов, а с 2012 года с Российским кардиологическим обществом.
      </p>
      <p>
        С 2020 года издательство начинает работать с двумя новыми проектами: «
        <Link to="/journals/russian-journal-of-church-history">
          Российский журнал истории Церкви
        </Link>
        » и «Академия медицины и спорта».
      </p>
      <p>
        Издательство поддерживает свою <b>образовательную</b> программу{" "}
        <b>«Научная информация и как с ней работать?»</b> в форме издания книг,
        методических пособий и обучающих программ для молодых ученых и
        специалистов, сталкивающихся с трудностями при написании качественной
        научной статьи. Программа также направлена на внедрение достижений
        отечественной науки в мировое профессиональное сообщество и повышение
        информированности ученых других стран о российском научном опыте.
      </p>
    </article>
  );
}

function Requisites() {
  return (
    <article>
      <h2>Реквизиты издательства</h2>
      <p>
        <b>Общество с ограниченной ответственностью «Силицея-Полиграф»</b>
      </p>
      <p>
        Юридический адрес: 119049, г. Москва, Москва, улица Шаболовка, 23-254
      </p>
      <p>Фактический адрес: 142281, г. Протвино, ул. Южная, 3-32</p>
      <p>Почтовый адрес: 142281, г. Протвино, ул. Южная, 3-32</p>
      <p>
        e-mail:{" "}
        <a href="mailto:cardio.nauka@yandex.ru">cardio.nauka@yandex.ru</a>
      </p>
      <p>
        Заказать и оплатить услуги издательства можно в разделе Услуги. Доступна
        оплата по картам. Оплата услуг осуществляется электронным платежом через
        ROBOKASSA (Visa, Mastercard, мобильным телефоном - МТС, Мегафон, Билайн,
        всеми электронными валютами, наличными через сеть терминалов, через
        интернет-банки и другими способами).
      </p>
      <p>
        В случае несоблюдения регламента оформления заказа или отсутствии
        информации о номере заказа в тексте назначения платежа все поступившие
        средства будут числиться в статусе "невыясненных" платежей. Оказание
        услуг в рамках таких платежей будет невозможно до момента полной
        идентификации плательщика и предмета оказания услуг. Оплачивайте услуги
        исключительно на основании сформированного платежного поручения.
      </p>
      <p>
        Для запроса реквизитов в исключительных случаях, когда заказ услуги
        через наши электронные сервисы не может быть осуществлен, направьте
        письмо на адрес{" "}
        <a href="mailto:guseva.silicea@yandex.ru">guseva.silicea@yandex.ru</a>
      </p>
    </article>
  );
}

function Policy() {
  return (
    <article>
      <h2>Политика издательства</h2>
      <p>
        Журналы, выпускаемые издательством <b>«Силицея-Полиграф»</b>, являются
        научными, специализированными и рецензируемыми. Каждый журнал имеет
        независимую редакционную коллегию, чья деятельность регулируется
        политиками и целями.
      </p>
      <p>
        Издания придерживаются принципов светскости, гуманности и демократии, не
        публикуют популярных материалов, не занимаются политической пропагандой,
        не обсуждают законопроекты.
      </p>
      <p>
        Издательство <b>«Силицея-Полиграф»</b>, осуществляет организационную и
        управляющую деятельность, направленную на выпуск номеров
        журнала-партнера, в соответствии с современными требованиями к
        оформлению печатных материалов международного уровня
      </p>
      <p>
        Журналы-партнеры (и организации, которые их поддерживают) самостоятельно
        отбирают, рецензируют, редактируют и готовят к публикации поступающие
        материалы, формируют собственную базу метаданных и статей.
      </p>
    </article>
  );
}

function Contacts() {
  return (
    <article>
      <h2>Контакты издательства</h2>
      <p>
        <b>Почтовый адрес издательства</b>:<br />
        ООО «Силицея-Полиграф». ул. Южная, 3-32, Протвино, 142281, Россия.
        <br />
        ул. Южная, 3-32, Протвино, 142281, Россия
      </p>
      <p>Тел. +7 985 768 4318</p>
      <p>
        <b>Часы работы:</b>
      </p>
      <p>10.00-17.00 понедельник, вторник, среда, четверг, пятница</p>
      <p>Выходные дни: суббота, воскресенье.</p>
      <p>
        <b>По вопросам, не связанным с подпиской и оплатой услуг:</b>
        <br />
        e-mail:{" "}
        <a href="mailto:cardio.nauka@yandex.ru">cardio.nauka@yandex.ru</a>
      </p>
      <p>
        <b>По вопросам публикации</b> обращаться <b>в Редакцию</b> конкретного
        журнала.
      </p>
      <p>
        <b>По вопросам подписки, оплаты услуг издательства</b> и
      </p>
      <p>
        <b>По вопросам размещения рекламно-информационных модулей</b>
      </p>
      <p>
        обращаться в <b>Отдел распространения:</b>
      </p>
      <p>Гусева Анна, e-mail: guseva.silicea@yandex.ru</p>
    </article>
  );
}

function PrivacyPolicy() {
  return (
    <article>
      <h2>Политика конфиденциальности</h2>
      <p>
        Настоящая Политика конфиденциальности (далее — Политика) разработана в
        соответствии с требованиями Федерального закона от 27.07.2006 г. №
        152-ФЗ (ред. от 29.07.2017) «О персональных данных» и определяет порядок
        обработки и защиты персональной информации о Пользователях, которую
        Общество с ограниченной ответственностью «Силицея-Полиграф» (далее —
        Издательство), может получить от Пользователей в своей деятельности.
      </p>

      <p>
        Издательство не собирает и не хранит персональные данные, не рассылает
        уведомительных, рекламных и информационных писем по базе Пользователей.
      </p>
      <p>
        Издательство получает только ту информацию о Пользователях, которая
        необходима для осуществления непосредственной деятельности, связанной с
        изданием журналов и книг от своих партнеров. С партнерами Издательства
        каждый Пользователь заключает свой договор об использовании персональных
        данных, позволяющий передавать информацию третьему лицу.
      </p>
      <p>
        К персональной информации, полученной таким образом, могут относиться:
        данные о рецензентах (фамилия, имя, отчество), данные об авторах
        (фамилия, имя, отчество; ученая степень, звание; место работы (включая
        почтовый адрес), должность; контактная информация (адрес электронной
        почты, телефон)), данные о подписчиках (фамилия, имя, отчество;
        контактная информация (адрес электронной почты, телефон); адрес для
        доставки заказа), данные о пользователях услугами (фамилия, имя,
        отчество; контактная информация (адрес электронной почты).
      </p>
      <p>
        Издательство не проверяет достоверность предоставляемой Пользователями
        персональной информации.
      </p>
    </article>
  );
}
function Partners() {
  return (
    <article>
      <h2>Наши партнеры</h2>
      <table
        className="unstyledTable"
        style={{
          marginTop: 48,
          width: "100%",
          rowGap: 12,
          borderCollapse: "separate",
          borderSpacing: 24,
        }}
      >
        <tbody>
          <tr key="r1">
            <td style={{ textAlign: "center" }}>
              <img src={ctap} />
            </td>

            <td />
            <td style={{ textAlign: "center" }}>
              <img src={ropniz} style={{ maxWidth: 250 }} />
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <img src={rkz} style={{ maxWidth: 250 }} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img src={nmniz} style={{ maxWidth: 250 }} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img src={rko} style={{ maxWidth: 250 }} />
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <img src={anri} style={{ width: 250 }} />
            </td>

            <td />
            <td style={{ textAlign: "center" }}>
              <img src={elpub} style={{ width: 250 }} />
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  );
}

export function AboutPage() {
  return (
    <InfoPanelWrapper>
      <InfoPanel>
        <TableOfContents>
          <ContentSection link="">Об издательстве</ContentSection>
          <ContentSection link="requisites">Реквизиты</ContentSection>
          <ContentSection link="policy">Политика</ContentSection>
          <ContentSection link="partners">Партнеры</ContentSection>
          <ContentSection link="contacts">Контакты</ContentSection>
          <ContentSection link="privacy-policy">
            Политика конфиденциальности
          </ContentSection>
        </TableOfContents>
      </InfoPanel>
      <div style={{ padding: 24, width: "100%" }}>
        <Routes>
          <Route path="" element={<AboutPublisher />} />
          <Route path="requisites" element={<Requisites />} />
          <Route path="policy" element={<Policy />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="partners" element={<Partners />} />
        </Routes>
      </div>
    </InfoPanelWrapper>
  );
}
