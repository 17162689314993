import { PropsWithChildren } from "react";
import cardiovascularTherapyAndPrevention from "../assets/images/cardiovascular-therapy-and-prevention.jpg";
import logo from "../assets/images/ctap.jpg";
import { Link } from "react-router-dom";

export function JournalMain() {
  return (
    <article>
      <h2>Кардиоваскулярная терапия и профилактика</h2>

      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 24,
          gap: 48 * 4,
        }}
      >
        <img width="150px" src={cardiovascularTherapyAndPrevention} />
        <img src={logo} />
      </span>
      <p>
        <b>«Кардиоваскулярная терапия и профилактика»</b> – научно-практический
        рецензируемый журнал. <b>Главный редактор</b> – академик РАН, доктор
        медицинских наук, профессор, <b>Драпкина Оксана Михайловна</b>
      </p>
      <p>
        С 2021 года журнал является официальным органом печати{" "}
        <b>
          Российского общества профилактики неинфекционных заболеваний (РОПНИЗ)
        </b>
        . Входит в перечень журналов, издаваемых при поддержке Российского
        кардиологического общества и Российской академии наук.
      </p>
      <p>
        Журнал публикует научные статьи по вопросам первичной и вторичной
        профилактики сердечно-сосудистых заболеваний (ССЗ) с помощью различных
        методов лечения, лекции кардиологов, оригинальные статьи, дискуссии,
        клинические обзоры и обзоры литературы, рекомендации и другую информацию
        для врачей.
      </p>
      <p>
        Журнал «Кардиоваскулярная терапия и профилактика» выпускается с 2002г.,
        включен в перечень изданий, рекомендованных для публикации статей,
        содержащих материалы диссертаций (ВАК), Scopus, EBSCO, IC и Российский
        индекс научного цитирования (РИНЦ).
      </p>
      <p>Журнал открытого доступа (OA, DOAJ).</p>
      <p>
        Полнотекстовые версии всех номеров размещены на сайте Научной
        электронной библиотеки – www.elibrary.ru, сайт журнала –
        <a href="https://cardiovascular.elpub.ru">
          https://cardiovascular.elpub.ru
        </a>
        .
      </p>
      <p>
        Правила для авторов:{" "}
        <a href="https://cardiovascular.elpub.ru/jour/about/submissions#authorGuidelines">
          https://cardiovascular.elpub.ru/jour/about/submissions#authorGuidelines
        </a>
      </p>
      <p>
        Подписка на журнал:{" "}
        <Link to="/subscription">http://roscardio.ru/subscription</Link>
      </p>
      <p>
        ISSN 1728-8800 (Print), ISSN 2619-0125 (Online), формат А4, 12 номеров в год, тираж – 5 000 экз.
      </p>
      <p>
        Журнал зарегистрирован Министерством РФ по делам печати,
        телерадиовещания и средств массовых коммуникаций 30.11.2001 г. (ПИ №
        77-11335)
      </p>
      <p>
        Основные направления, по которым принимаются материалы для публикации в
        журнале
      </p>
      <ul>
        <li>Артериальная гипертония</li>
        <li>Атеросклероз</li>
        <li>Ишемическая болезнь сердца</li>
        <li>Периферический атеросклероз</li>
        <li>Сердечная недостаточность</li>
        <li>Хроническая болезнь почек</li>
        <li>Нарушения сердечного ритма</li>
        <li>Диабет, ожирение и метаболический синдром</li>
        <li>Цереброваскулярные заболевания</li>
        <li>Кардиомиопатии</li>
        <li>ССЗ и коморбидные состояния</li>
        <li>Генетические и другие фундаментальные аспекты ССЗ</li>
        <li>Питание и ССЗ</li>
        <li>Липидный обмен и ССЗ</li>
        <li>Алкоголь и ССЗ</li>
        <li>Курение и ССЗ</li>
        <li>Психосоциальные аспекты ССЗ</li>
        <li>Биомаркеры ССЗ</li>
        <li>Оценка риска и скрининг ССЗ</li>
        <li>Клинико-инструментальная диагностика ССЗ</li>
        <li>Клинические исследования при ССЗ 2, 3 и 4 фазы</li>
        <li>Возраст-специфические аспекты ССЗ</li>
        <li>ССЗ у детей</li>
        <li>Фармакотерапия ССЗ</li>
        <li>Приверженность к лечению ССЗ</li>
        <li>Интервенционные и хирургические вмешательства при ССЗ</li>
        <li>Телемедицина и ССЗ</li>
        <li>Кардиореабилитация</li>
        <li>Профилактические программы при ССЗ</li>
        <li>Физическая активность и ССЗ</li>
        <li>Физические тренировки и ССЗ</li>
        <li>Спортивная кардиология</li>
        <li>Загрязнение окружающей среды и ССЗ</li>
        <li>Социально-экономические аспекты ССЗ</li>
        <li>Общественное здоровье и ССЗ</li>
      </ul>
    </article>
  );
}

function JournalContact({
  roles,
  phone,
  email,
  children,
}: PropsWithChildren<{ roles: string[]; phone?: string; email: string }>) {
  return (
    <>
      {roles.map((r) => (
        <p>
          <b>{r}</b>
        </p>
      ))}
      <p>
        <i>{children}</i>
      </p>

      <p>
        {phone && `${phone}; `}email: <a href={email}>{email}</a>
      </p>
    </>
  );
}

export function JournalContacts() {
  return (
    <article>
      <JournalContact
        roles={["Шеф-редактор"]}
        phone="+7-985-768-43-18"
        email="cardiovasc.journal@yandex.ru"
      >
        Родионова Юлия
      </JournalContact>
      <JournalContact
        roles={[
          "Отдел распространения",
          "Отдел рекламы, размещение дополнительных материалов",
        ]}
        email="guseva.silicea@yandex.ru"
      >
        Гусева Анна
      </JournalContact>
    </article>
  );
}

export function JournalArticlePreparation() {
  return (
    <article>
      <p>
        Журнал является <b>журналом Открытого доступа</b> и размещает
        полнотекстовые статьи на сайте журнала сразу после публикации номера (в
        PDF и XTML форматах).
      </p>
      <ol>
        <li>
          <p>
            Авторам по <i>индивидуальному запросу</i> предоставляются следующие
            возможности ускоренного рассмотрения и размещения статей
          </p>
          <p>
            <b>До рецензирования:</b>
          </p>
          <ul>
            <li>
              Консультация по приведению рукописи к необходимым стандартам.
            </li>
          </ul>
          <p>
            <b>После рецензирования:</b>
          </p>
          <ul>
            <li>Публикация статьи в ближайшем номере.</li>
            <li>
              Открытый доступ к предварительной версии статьи на сайте (сразу
              после получения положительного ответа).
            </li>
            <li>
              Открытый доступ к макетированной версии статьи на сайте (после
              получения положительного ответа).
            </li>
          </ul>
        </li>
        <li>
          <p>
            Оплатить публикацию{" "}
            <i>
              (после получения уведомления об успешном прохождении
              рецензирования)
            </i>{" "}
            можно следующим образом:
          </p>
          <ol>
            <li>
              <p>Индивидуально (как физическое лицо)</p>
              <p>
                Ссылка для оплаты на сервисе РОБОКАССА:{" "}
                <a href="https://roscardio.robo.market/product/1518555">
                  https://roscardio.robo.market/product/1518555
                </a>
              </p>
            </li>
            <li>
              <p>Через свою организацию.</p>
              <p>
                <b>ВНИМАНИЕ:</b> у вашей организации может быть уже заключенный
                договор с журналом. От этого зависят условия оплаты публикации.
              </p>
              <p>
                <b>
                  Стоимость публикации статьи в журнале в электронном виде с
                  присвоением doi:
                </b>
              </p>
              <p>
                44 990-00 руб – для физических лиц (через РОБОКАССА)
                <br /> 79 990-00 руб – для юридических лиц (с подписанием
                договора)
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </article>
  );
}
