import Markdown, { Options as MDOptions } from "react-markdown";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";



export default function MarkdownWrapper({ children, remarkPlugins, ...opts }: MDOptions) {
  return <Markdown remarkPlugins={[remarkGfm, ...(remarkPlugins ?? [])]} components={{
    'a'(props) {
      const { children, href, node, ref, ...rest } = props
      const cleanHref = href?.replace(/^https?:\/\/roscardio.ru/, '')
      return cleanHref?.startsWith('/') ? <Link {...rest} to={cleanHref!}>{children}</Link> : <a {...rest} href={cleanHref}>{children}</a>
    }
  }} {...opts}>
    {children}
  </Markdown>
}
