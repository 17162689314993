import { type } from "@testing-library/user-event/dist/type";
import { VoidFunctionComponent } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { InfoPanel, InfoPanelWrapper } from "../common/InfoPanel";
import { ContentSection, TableOfContents } from "../common/TableOfContents";
import {
  journals,
  Journal,
  LinkableJournalSection,
  JournalSection,
} from "../journals/List";

const FCWrapper = ({ fc }: { fc: React.FC<{}> }) => fc({});
const wrapFC = (s: JournalSection) =>
  (s as any).fc ? (
    <FCWrapper fc={(s as { fn: React.FC }).fn} />
  ) : (
    (s as Exclude<JournalSection, { fc: true; fn: React.FC }>)
  );
type JournalSectionName = keyof Omit<
  Journal,
  "id" | "name" | "customSections" | "chiefEditorMessage" | "mainPage" | "link"
>;
const journalSectionNames: Record<JournalSectionName, string> = {
  members: "Редакционная коллегия и редакционный совет",
  rules: "Правила для авторов",
  contacts: "Контакты",
};

function JournalPage({ journal }: { journal: Journal }) {
  const { pathname } = useLocation();
  const journalSection = (
    id: string,
    s: LinkableJournalSection,
    name?: string
  ) =>
    typeof s === "string" ? (
      <ContentSection href={s}>
        {name ?? journalSectionNames[id as JournalSectionName]}
      </ContentSection>
    ) : (
      <ContentSection link={id}>
        {name ?? journalSectionNames[id as JournalSectionName]}
      </ContentSection>
    );
  const journalRoute = (id: string, s: LinkableJournalSection) =>
    typeof s !== "string" && <Route path={id} element={wrapFC(s)} />;
  return (
    <>
      <InfoPanel>
        <TableOfContents>
          {journalSection("members", journal.members)}
          {journalSection("rules", journal.rules)}
          {journalSection("contacts", journal.contacts)}
          {journal.customSections?.map(({ id, name, section }) =>
            journalSection(id, section, name)
          )}
        </TableOfContents>
        {pathname == "/journals/" + journal.id && journal.chiefEditorMessage && (
          <div style={{ padding: 24, paddingBottom: 24 }}>
            <h3>От главного редактора</h3>
            <article style={{ marginTop: 12 }}>
              {wrapFC(journal.chiefEditorMessage.short)}
            </article>
            <Link
              to="chief-editor-message"
              className="forceLinkUnderline"
              style={{
                display: "block",
                marginTop: 24,
                textAlign: "right",
                width: "100%",
              }}
            >
              Подробнее...
            </Link>
          </div>
        )}
      </InfoPanel>
      <div
        style={{
          padding: 24,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 24,
        }}
      >
        <span style={{ display: "flex", justifyContent: "center" }}>
          <a
            href={journal.link}
            style={{
              padding: 12,
              textAlign: "center",
              display: "flex",
              backgroundColor: "#145077",
              color: "white",
              borderRadius: 10,
            }}
          >
            Перейти на страницу журнала
          </a>
        </span>
        <Routes>
          <Route path="/" element={journal.mainPage} />
          {journalRoute("members", journal.members)}
          {journalRoute("rules", journal.rules)}
          {journalRoute("contacts", journal.contacts)}
          {journal.chiefEditorMessage &&
            journalRoute(
              "chief-editor-message",
              <article>{wrapFC(journal.chiefEditorMessage.long)}</article>
            )}
          {journal.customSections?.map(({ id, section }) =>
            journalRoute(id, section)
          )}
        </Routes>
        {pathname != `/journals/${journal.id}` && (
          <div style={{ textAlign: "right" }}>
            <Link className="forceLinkUnderline" to={`/journals/${journal.id}`}>
              Назад
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export function Journals() {
  return (
    <InfoPanelWrapper>
      <Routes>
        {journals.map((j) => (
          <Route path={`${j.id}/*`} element={<JournalPage journal={j} />} />
        ))}
      </Routes>
    </InfoPanelWrapper>
  );
}
