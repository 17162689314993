import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Navbar } from "./Navbar";
import { AboutPage } from "./pages/About";
import { Authors } from "./pages/Authors";
import { Journals } from "./pages/Journals";
import { MainPage } from "./pages/Main";
import { SubscriptionPage } from "./pages/Subscription";
import { WIPPage } from "./pages/WIPPage";
import { Sidebar } from "./Sidebar";
import "./styles.scss";

export default function App() {
  return (
    <BrowserRouter>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          minWidth: 1200,
          position: "relative",
        }}
      >
        <div
          style={{
            maxWidth: 1200,
            backgroundColor: "#fff",
            border: "1px solid #ddd",
          }}
        >
          <Header />
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ flex: 1 }}>
              <Routes>
                <Route path="/subscription" element={<SubscriptionPage />} />
                <Route path="/about/*" element={<AboutPage />} />
                <Route path="/journals/*" element={<Journals />} />
                <Route path="/authors" element={<Authors />} />
                <Route path="/books" element={<WIPPage />} />
                <Route path="/services" element={<WIPPage />} />
                <Route path="/edu" element={<WIPPage />} />
                <Route path="/" element={<MainPage />} />
              </Routes>
            </div>
            <Sidebar />
          </div>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}
