import * as RussianJournalOfCardiology from "./RussianJournalOfCardiology";
import * as CardiovascularTherapyAndPrevention from "./CardiovascularTherapyAndPrevention";
import * as RussianJournalOfChurchHistory from "./RussianJournalOfChurchHistory";
import * as RationalPharmacotherapyInCardiology from "./RationalPharmacotherapyInCardiology";

export type JournalSection =
  | JSX.Element
  | React.ReactNode
  | { fc: true; fn: React.FC };

export type LinkableJournalSection<L = string, T = JournalSection> = L | T;
export type LinkableJournalSections<K extends keyof any> = {
  [S in K]: LinkableJournalSection;
};

export type JournalCustomSection = {
  id: string;
  name: string;
  section: LinkableJournalSection;
};

function MainPage1() {
  return <div></div>;
}
export type Journal = LinkableJournalSections<
  "members" | "rules" | "contacts"
> & {
  id: string;
  name: string;
  link: string;
  mainPage: React.ReactNode;
  customSections?: JournalCustomSection[];
  chiefEditorMessage?: {
    short: JournalSection;
    long: JournalSection;
  };
};

export const journals: Array<Journal> = [
  {
    id: "russian-journal-of-cardiology",
    name: "Российский кардиологический журнал",
    link: "http://russjcardiol.elpub.ru/jour/index",
    members: "https://russjcardiol.elpub.ru/jour/about",
    contacts: <RussianJournalOfCardiology.JournalContacts />,
    rules:
      "https://russjcardiol.elpub.ru/jour/about/submissions#authorGuidelines",
    customSections: [
      {
        id: "editors",
        name: "Редакторы, рассматривающие обращения авторов",
        section: <RussianJournalOfCardiology.JournalReviewers />,
      },
      {
        id: "translator",
        name: "Ответственный переводчик",
        section: <RussianJournalOfCardiology.JournalTranslator />,
      },
      {
        id: "recommendations",
        name: "Рекомендации по оформлению статей",
        section: <RussianJournalOfCardiology.JournalArticleFormatting />,
      },
      {
        id: "preparation",
        name: "Подготовка статьи",
        section: <RussianJournalOfCardiology.JournalArticlePreparation />,
      },
    ],
    mainPage: <RussianJournalOfCardiology.JournalMainPage />,
    chiefEditorMessage: {
      long: (
        <>
          <p>
            <b>Глубокоуважаемые коллеги!</b>
          </p>
          <p>
            Мы открыли новую страницу в истории нашего общества, отражающую
            единодушно одобренный Российским национальным конгрессом кардиологов
            курс на повышение роли Российского кардиологического общества в
            реализации государственной политики в области здравоохранения,
            образования и науки.
          </p>
          <p>
            Среди неотложных задач – интенсификация работы в части повышения
            научно-образовательной и организационно-методической активности,
            интеграции в международное научное сообщество и, прежде всего, в
            структуры Eвропейского кардиологического общества, подготовки и
            издания национальных рекомендаций и перевода международных
            рекомендаций на русский язык.
          </p>
          <p>
            Для достижения этих целей исключительно важным представляется
            развитие Российского кардиологического журнала, который имеет все
            основания по праву стать ведущим профильным периодическим
            научно-практическим изданием, с высоким импакт-фактором и индексом
            цитирования, публикующим результаты самых современных
            фундаментальных и прикладных исследований, статьи наших зарубежных
            коллег, национальные и международные рекомендации.
          </p>
          <p>
            Это нелегкая задача, и ваше заинтересованное и деятельное участие в
            работе журнала – залог ее успешного решения. В этом контексте особую
            ценность для нас представляют давние добросердечные отношения
            редакции с читателями и авторами публикаций, региональными
            отделениями, секциями и рабочими группами Российского
            кардиологического общества. Мы очень дорожим этим творческим
            сотрудничеством и надеемся, что его дальнейшее развитие будет
            способствовать осуществлению наших общих планов, цель и смысл
            которых – сохранение здоровья и спасение жизней наших
            соотечественников.
          </p>
          <p>Главный редактор</p>
          <p>Российского кардиологического журнала,</p>
          <p>Президент Российского</p>
          <p>кардиологического общества,</p>
          <p>академик РАМН</p>
          <p style={{ textAlign: "right" }}>
            <b>Е. В. Шляхто</b>
          </p>
        </>
      ),
      short: (
        <>
          <p>
            <b>Глубокоуважаемые коллеги!</b>
          </p>
          <p>
            Мы открыли новую страницу в истории нашего общества, отражающую
            единодушно одобренный Российским национальным конгрессом кардиологов
            курс на повышение роли Российского кардиологического общества в
            реализации государственной политики в области здравоохранения,
            образования и науки.
          </p>
        </>
      ),
    },
  },
  {
    id: "cardiovascular-therapy-and-prevention",
    name: "Кардиоваскулярная терапия и профилактика",
    link: "http://cardiovascular.elpub.ru/jour/index",
    members: "https://cardiovascular.elpub.ru/jour/about",
    contacts: <CardiovascularTherapyAndPrevention.JournalContacts />,
    rules:
      "https://cardiovascular.elpub.ru/jour/about/submissions#authorGuidelines",
    mainPage: <CardiovascularTherapyAndPrevention.JournalMain />,
    customSections: [
      {
        id: "preparation",
        name: "Подготовка статьи",
        section: (
          <CardiovascularTherapyAndPrevention.JournalArticlePreparation />
        ),
      },
    ],
  },
  {
    id: "russian-journal-of-church-history",
    name: "Российский журнал истории Церкви",
    link: "https://churchhistory.elpub.ru/jour/index",
    members: "https://churchhistory.elpub.ru/jour/about",
    rules:
      "https://churchhistory.elpub.ru/jour/about/submissions#authorGuidelines",
    contacts: <RussianJournalOfChurchHistory.JournalContacts />,
    mainPage: <RussianJournalOfChurchHistory.JournalMain />,
  },

  {
    id: "rational-pharmacotherapy-in-cardiology",
    name: "Рациональная Фармакотерапия в Кардиологии",
    link: "https://www.rpcardio.online/jour/index",
    members: "https://www.rpcardio.online/jour/about",
    rules:
      "https://www.rpcardio.online/jour/about/submissions#authorGuidelines",
    contacts: <RationalPharmacotherapyInCardiology.JournalContacts />,
    mainPage: <RationalPharmacotherapyInCardiology.JournalMain />,
  },
];
