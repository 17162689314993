import { PropsWithChildren } from "react";
import styles from "./InfoPanel.module.scss";

export function InfoPanel({ children }: PropsWithChildren<{}>) {
  return <div className={styles.infoPanel}>{children}</div>;
}

export function InfoPanelWrapper({ children }: PropsWithChildren<{}>) {
  return <div className={styles.infoPanelWrapper}>{children}</div>;
}
