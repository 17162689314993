import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import { hydrate, render } from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';

//const root = document.getElementById('root')!;
//if (root.hasChildNodes()) hydrate(<App />, root);
//else render(<App />, root);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
