import { PropsWithChildren } from "react";
import styles from "./Main.module.scss";
import banner from "../assets/images/mainpage.jpg";
import bookKach from "../assets/images/kach.jpg";
import bookNauchrec from "../assets/images/nauchrec.jpg";
import bookRosrek from "../assets/images/rosrek.jpg";

function BookAd({
  children,
  type,
  image,
}: PropsWithChildren<{
  type: string;
  image: string;
}>) {
  return (
    <li className={styles.bookAd}>
      <h3 className={styles.bookAdCentered}>{children}</h3>
      <p>{type}</p>
      <div className={styles.bookAdCentered}>
        <img src={image} />
      </div>
    </li>
  );
}

export function MainPage() {
  return (
    <div className={styles.mainPage}>
      <img src={banner} width="663px" height="484px" />
      <h2 className={styles.title}>Издательство Силицея-Полиграф</h2>
      <ul className={styles.bookAds}>
        <BookAd type="учебное пособие" image={bookKach}>
          Качественная клиническая практика с основами доказательной медицины
        </BookAd>
        <BookAd type="методические рекомендации" image={bookNauchrec}>
          Научное рецензирование: как стать рецензентом?
        </BookAd>
        <BookAd type="учебное пособие" image={bookRosrek}>
          Статины в клинической практике
        </BookAd>
      </ul>
    </div>
  );
}
