export function Authors() {
  return (
    <article
      style={{
        paddingLeft: 24,
        paddingTop: 0,
        display: "flex",
        flexFlow: "column wrap",
        gap: "0.5em",
      }}
    >
      <h1>УВАЖАЕМЫЕ АВТОРЫ!</h1>
      <p>
        Наша компания издает печатную продукцию, которая исключительно
        обслуживает нужды сообществ, поддерживающих журналы.{" "}
        <b> Мы не принимаем сторонних заказов.</b>
      </p>
      <p>
        С текущей деятельностью каждого из печатных изданий вы можете
        ознакомиться на официальных страницах.
      </p>
      <h1>УВАЖАЕМЫЕ АВТОРЫ!</h1>
      <p>
        Издательство журнала не рассылает авторские экземпляры ни в бумажном, ни
        в электронном виде.
      </p>
    </article>
  );
}
