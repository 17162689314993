import { PropsWithChildren } from "react";
import styles from "./TableOfContents.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";

export function ContentSection({
  children,
  link,
  href,
}: PropsWithChildren<{
  link?: string;
  href?: string;
}>) {
  const cx = classNames(styles.contentSection, "forceUnhoverLink");
  return link ? (
    <Link className={cx} to={link}>
      {children}
    </Link>
  ) : (
    <a href={href ?? "#"} className={cx}>
      {children}
    </a>
  );
}

export function TableOfContents({ children }: PropsWithChildren<{}>) {
  return <ul className={styles.tableOfContents}>{children}</ul>;
}
