import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Navbar.module.scss";
import { Link, useLocation } from "react-router-dom";
import { journals } from "./journals/List";

type MenuItemProps = PropsWithChildren<{ link?: string; href?: string }>;

function MenuItem({ link, href, children }: MenuItemProps) {
  const { pathname } = useLocation();
  const cx = classNames(styles.navbarMenuItem, "forceUnhoverLink", {
    [styles.navbarMenuCurrent]:
      link && (pathname == link || (link != "/" && pathname.startsWith(link))),
  });
  return link ? (
    <Link className={cx} to={link}>
      {children}
    </Link>
  ) : (
    <a className={cx} href={href}>
      {children}
    </a>
  );
}

type DropdownProps = {
  name: string;
} & MenuItemProps;

function Dropdown({ name, children, link, href }: DropdownProps) {
  const { pathname } = useLocation();
  const cx = classNames(styles.navbarDropdown, "forceUnhoverLink", {
    [styles.navbarMenuCurrent]:
      link && (pathname == link || (link != "/" && pathname.startsWith(link))),
  });
  return link ? (
    <Link className={cx} to={link}>
      <span className={styles.navbarMenuItem}>{name}</span>
      <ul className={styles.navbarDropdownContent}>{children}</ul>
    </Link>
  ) : (
    <a className={cx} href={href ?? "#"}>
      <span className={styles.navbarMenuItem}>{name}</span>
      <ul className={styles.navbarDropdownContent}>{children}</ul>
    </a>
  );
}

export function Navbar() {
  return (
    <nav className={styles.navbar}>
      <MenuItem link="/">Главная</MenuItem>
      <Dropdown name="О Нас" link="/about">
        <MenuItem link="/about">Об издательстве</MenuItem>
        <MenuItem link="/about/requisites">Реквизиты</MenuItem>
        <MenuItem link="/about/policy">Политика</MenuItem>
        <MenuItem link="/about/partners">Партнеры</MenuItem>
        <MenuItem link="/about/contacts">Контакты</MenuItem>
        <MenuItem link="/about/privacy-policy">
          Политика конфиденциальности
        </MenuItem>
      </Dropdown>
      <Dropdown name="Журналы">
        {journals.map(({ id, name }) => (
          <MenuItem link={`/journals/${id}`}>{name}</MenuItem>
        ))}
      </Dropdown>
      <Dropdown name="Книги" link="/books">
        {/* <MenuItem>Учебные пособия</MenuItem>
        <MenuItem>Сборники</MenuItem>
        <MenuItem>Тезисы</MenuItem>
        <MenuItem>Монографии</MenuItem> */}
      </Dropdown>
      <MenuItem link="/authors">Авторам</MenuItem>
      <MenuItem link="/services">Услуги</MenuItem>
      <MenuItem link="/edu">Обучение</MenuItem>
      <MenuItem link="/subscription">Подписка</MenuItem>
    </nav>
  );
}
