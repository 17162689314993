export function WIPPage() {
  return (
    <article
      style={{
        margin: 12,
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <h1 style={{ color: "red", fontWeight: "bold" }}>
        Страница в разработке
      </h1>
      <p>
        Уважаемый пользователь! Мы приносим свои извинения.
        <br /> Эта страница на текущий момент находится в разработке. Сейчас вы
        видите сообщение-заглушку. <br />В будущем здесь может появиться
        какой-либо контент.
      </p>
    </article>
  );
}
