import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Header.module.scss";
import logo from "./assets/images/logo.png";

function SubheaderElement({
  children,
  float,
}: PropsWithChildren<{ float?: "left" | "right" }>) {
  return (
    <div className={classNames({ [styles.floating]: float })}>
      <h1 style={{ float: float }}>{children}</h1>
    </div>
  );
}

export function Header() {
  return (
    <div className={styles.header}>
      <img className={styles.banner} src={logo}></img>
      <div className={styles.subheader}>
        <SubheaderElement float="right">Книги</SubheaderElement>
        <h1>•</h1>
        <SubheaderElement>Журналы</SubheaderElement>
        <h1>•</h1>
        <SubheaderElement float="left">Образование</SubheaderElement>
      </div>
      <div className={styles.languageChip}></div>
    </div>
  );
}
